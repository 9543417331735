<template>
	<div>
		<header class="flex justify-center bg-gradient-to-b from-[#0B101E] to-[#18191B] bg-[#12161B] py-10">
			<div>
				<div class="flex justify-center">
					<img v-if="theme === 'dark'" src="@/assets/logo.svg" class="object-contain h-20 w-auto"
					     alt="Pre ico pad"/>
				</div>
				<div class="flex justify-center mt-10 inline xl:hidden select-none">
					<div @click="connectWallet"
					     class="do-btn p-0 flex justify-start items-center h-auto max-w-[60%] md:max-w-full pr-3 group"
					     :class="connected_as === '' ? 'w-96 md:w-min' : 'w-full'">
						<div class="h-12 w-12 rounded-full bg-[#2EC56A] flex items-center justify-center
							group-hover:shadow-md flex-shrink-0 mr-2">
							<i class="bx bx-wallet text-2xl"></i>
						</div>
						<p class="truncate mx-auto">
							{{ connected_as !== "" ? connected_as : "Connect wallet" }}
						</p>
					</div>
				</div>
				<div class="flex item-center justify-center mt-4">
					<a href="https://github.com/interfinetwork/smart-contract-audits/blob/main/MelodityPS_AuditReport_InterFi.pdf"
					   target="_blank" rel="noopener"
					   class="flex items-center justify-center">
						<small class="font-bold text-gray-300 text-center px-6">
							Pre ICO Pad audited by InterFi,
							<br>
							check it here.
						</small>
					</a>
				</div>
			</div>
		</header>

		<main class="grid grid-cols-1 xl:grid-cols-3 gap-4 mb-18">
			<div class="xl:col-start-2">
				<div class="justify-center mt-6 hidden xl:flex select-none">
					<div @click="connectWallet"
					     class="do-btn p-0 flex justify-start items-center h-auto w-min max-w-full pr-3 group">
						<div class="h-12 w-12 rounded-full bg-[#2EC56A] flex items-center justify-center
							group-hover:shadow-md flex-shrink-0">
							<i class="bx bx-wallet text-2xl"></i>
						</div>
						<p class="truncate px-2">
							{{ connected_as !== "" ? connected_as : "Connect wallet" }}
						</p>
					</div>
				</div>
				<div class="flex justify-center">
					<!-- card-->
					<div
						class="bg-gradient-to-bl from-[#414A65] to-[#5A4663] backdrop-filter backdrop-blur
                            xl:mt-6 px-5 py-5 md:px-10 md:py-5 w-11/12 md:w-auto xl:w-full md:rounded-[4rem] mb-12
							rounded-[3rem] relative">
						<div v-if="isTestnet" class="bg-red-500 rounded-full py-1 px-4 text-xs font-bold text-white
							ml-auto w-min whitespace-nowrap mb-2">
							Alert: You're in testnet
						</div>
						<h5 class="text-center text-white text-xl">Melodity - Pre ICO pad</h5>
						<div class="mt-6"/>
						<div class="grid grid-cols-1">
							<!-- first field-->
							<div class="do-input-container">
								<h5 class="do-input-label text-xs select-none flex items-center">
									<span>Buy</span>
									<span class="font-bold bg-[#5D62EB] rounded-full text-white md:hidden
										justify-center items-center select-none flex ml-auto px-4 pt-1">
										BNB
					                </span>
								</h5>
								<div class="grid grid-cols-4 gap-4 mt-2">
									<input type="text" placeholder="Insert Number" id="buy" v-model="amount_to_buy"
									       v-cleave="bnb_cleave"
									       class="do-input border border-[#7d81ef] rounded-md">
									<span class="font-bold bg-[#5D62EB] rounded-full text-white h-full md:flex
										justify-center items-center select-none hidden">
										BNB
					                </span>
									<small class="font-light text-gray-100 p-1 text-xs col-start-1
										md:col-span-2 col-span-full select-none">
										1 BNB ≈ {{ bnb_price }} USD
									</small>
									<small class="font-light text-gray-100 p-1 text-right text-xs col-start-3
										col-span-2 select-none md:block hidden">
										Updated at {{ bnb_price_last_update }}
									</small>
								</div>
							</div>
							<!-- second field-->
							<div class="do-input-container select-none">
								<h5 class="do-input-label text-xs flex items-center">
									<span>Get</span>
									<span class="font-bold bg-[#5D62EB] rounded-full text-white md:hidden
										justify-center items-center select-none flex ml-auto px-4 pt-1">
										MELD
					                </span>
								</h5>
								<div class="grid grid-cols-4 gap-4 mt-2">
									<div class="do-input">
										{{ meld }}
									</div>
									<span
										class="font-bold text-grey-200 text-opacity-8 flex items-center
					                    h-full md:flex justify-center items-center hidden">
					                    MELD
					                </span>
								</div>
								<small class="text-xs font-normal">
									The actual value may differ from what is displayed due to price fluctuation.
								</small>
							</div>
							<!-- referral checkbox-->
							<collapsible v-model="referral" :align-content="false" :has-icon="false"
							             class="mt-3">
								<template #title>
									<div class="inline-flex items-center col-span-full w-full pl-4 flex items-center
										text-white select-none"
									     @click.prevent>
										<i v-if="!referral" class='bx bx-square-rounded text-xl'></i>
										<i v-else class='bx bxs-check-square text-xl'></i>
										<div class="ml-3">Have a referral ?</div>
									</div>
								</template>
								<template #content>
									<div class="px-6">
										<div class="grid grid-cols-4 gap-4">
											<input type="text"
											       placeholder="A123B456CD"
											       v-model="referral_code"
											       maxlength="10"
											       class="do-input border border-[#7d81ef] rounded-md">
										</div>
									</div>
								</template>
							</collapsible>
						</div>

						<!-- submit button-->
						<div class="flex items-center justify-center mt-6">
							<button @click="buy" class="do-btn py-3 px-8 rounded-full w-min whitespace-nowrap">
								Buy $MELD
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="flex xl:pl-12 xl:justify-start pl-0 justify-center items-center order-first xl:order-last
				text-white m-4 select-none">
				<div class="grid grid-cols-1 gap-4">
					<div class="text-left align-text-bottom">
						<h1 class="text-xl text-bold text-[#B6B6FF]">Instructions</h1>
						<ul class="text-sm font-light">
							<li class="mt-6">
								1. Connect your wallet
							</li>
							<li>
								2. Insert the amount
							</li>
							<li>
								3. Buy $MELD
							</li>
							<li>
								4.
								<a href="https://docs.melodity.org/wiki/wiki" target="_blank" rel="noopener"
								   class="font-semibold">
									Add $MELD to your wallet
								</a>
							</li>
							<li>
								5. Unlock your first batch of $MELD
								<a href="https://lock.melodity.org" target="_blank" rel="noopener"
								   class="font-semibold">
									here
								</a>
							</li>
						</ul>
					</div>
					<div class="text-left xl:mt-8">
						<h1 class="text-xl text-bold text-[#B6B6FF]">Requisites</h1>
						<ul class="text-sm font-light">
							<li class="mt-6">
								Min. 1 BNB
							</li>
							<li>
								Max. Unlimited
							</li>
							<li>
								Network. Binance Smart Chain (BSC)
							</li>
						</ul>
					</div>
					<div class="mt-8 flex items-center justify-center xl:col-span-1 col-span-full">
						<a class="rounded-full px-6 py-2 bg-[#B5B6FF]" href="mailto:support@melodity.org">
							Support
						</a>
					</div>
				</div>
			</div>
			<transaction-dialog
				v-bind="transaction"
				v-model:open="transaction.open"
				v-model:time="transaction.time"
				v-model:confirmations="transaction.confirmations"
			/>
		</main>
	</div>
</template>

<script>
import {defineComponent} from 'vue';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import {DateTime} from "luxon"
import Collapsible from "@/components/Collapsible";
import Toaster from "@/composition/toaster.ts";
import Overlay from "@/components/Overlay";
import TransactionDialog from "@/components/TransactionDialog";

const web3 = require("web3")
const private_sale_abi = require("@/assets/private-sale.json")

const testnet = false

// REAL SMART CONTRACT ADDRESS: 0x20EE0f7bCea12c37A68CF22264013e32C5E736fF
// TESTNET SMART CONTRACT ADDRESS: 0xa2f12EBB996Dfc683619878716947175F7797423
const private_sale_address = testnet ? "0xa2f12EBB996Dfc683619878716947175F7797423" : "0x20EE0f7bCea12c37A68CF22264013e32C5E736fF"

export default defineComponent({
	name: 'Home',
	components: {TransactionDialog, Overlay, Collapsible},
	data: () => ({
		theme: "",
		web3: null,
		private_sale: null,
		connected_as: "",
		provider_options: {},
		web3_modal: null,
		max_bnb: 0.,
		referral_code: "",
		amount_to_buy: "",
		bnb_price: null,
		bnb_price_last_update: null,
		money_type: "bnb",
		meld: "0.00",
		referral: false,
		bnb_cleave: {
			numeral: true
		},
		transaction: {
			open: false,
			confirmations: 0,
			tx: "",
			time: 0,
			interval: -1
		}
	}),
	methods: {
		prettyNumber(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		renderNumber(value, allowed_decimals = 18) {
			// if the provided value is not a string stringify it
			if (!(value instanceof String)) {
				value = value.toString()
			}
			let integer = "0"
			// check if the value has at least an integer part, in case it has one, prettify it
			if (value.length > allowed_decimals) {
				integer = this.prettyNumber(value.substr(0, value.length - allowed_decimals))
			}
			let decimals = "00"
			// check if the value has integer part
			if (value.length > allowed_decimals) {
				// if it has retrieve only the decimal part
				decimals = value.substr(value.length - allowed_decimals)
			} else {
				// otherwise it is a decimal number, take it all
				decimals = `${"0".repeat(allowed_decimals - value.length)}${value}`
			}
			for (let i = decimals.length - 1; i >= 2; i--) {
				// remove last zeros if any
				if (decimals[i] === "0") {
					decimals = decimals.slice(0, -1)
				} else {
					break
				}
			}
			return `${integer}.${decimals.substr(0, decimals.length > 12 ? 12 : decimals.length)}`
		},

		async connectWallet() {
			if (this.connected_as === "") {
				// show web3 modal popup
				const provider = await this.web3_modal.connect();

				// init provider and contract instance
				this.web3 = new web3(provider)
				this.private_sale = new this.web3.eth.Contract(private_sale_abi, private_sale_address)

				// try to connect to a wallet
				try {
					let r;
					if (!!provider.wc) {
						r = await this.web3.eth.getAccounts()
					} else {
						r = await this.web3.eth.requestAccounts()
					}

					// mark the user as connected and save the wallet address
					this.connected = true
					this.connected_as = r[0]

					// send a notification stating a successful connection
					new Toaster({
						title: `Wallet connected!`,
						message: `Successfully connected as ${r[0]}`,
						type: "success"
					})

					await this.getBnbBalance()
				} catch (r) {
					// an error occurred, show an error message and go on
					new Toaster({
						code: r.code,
						message: r.message,
					})
				}
			}
		},
		async getBnbBalance() {
			this.max_bnb = this.renderNumber(await this.web3.eth.getBalance(this.connected_as))
		},
		async buy() {
			await this.connectWallet()
			if (this.amount_to_buy && +this.amount_to_buy) {
				let bnb_amount = (BigInt(+this.amount_to_buy * 10 ** 12) * BigInt(10 ** 6)).toString();

				this.private_sale.methods.buy(this.referral_code ?? "").send({
					from: this.connected_as,
					value: bnb_amount,
					gas: 1000000,
					gasPrice: 10000000000
				})
					.on("transactionHash", (tx) => {
						// open a popup overlay
						this.transaction.tx = tx
						this.transaction.confirmations = this.transaction.time = 0
						this.transaction.open = true
					})
					.on("confirmation", (confirmations) => {
						if (this.transaction.open) {
							this.transaction.confirmations = confirmations
						}
					})
					.on("receipt", r => {
						// transaction confirmed
						this.transaction.open = false
						// send a notification stating a successful transaction
						new Toaster({
							title: `Melodity $MELD bought!`,
							message: `You have successfully purchased ${this.amount_to_buy} BNB of Melodity $MELD
								at the price of 0.025 $ per unit.
								<br>
								Explore your locks
								<a href="https://lock.melodity.org/" target="_blank" rel="noopener"
									class="text-indigo-200 font-semibold">here</a>.
							`,
							type: "success"
						})
					})
					.on("error", r => {
						let parsed_msg = JSON.parse(r.message.replace("Transaction has been reverted by the EVM:", ""))
						// an error occurred, show an error message and go on
						new Toaster({
							code: r.code,
							message: `Transaction has been reverted due to an internal error, check it
								<a href="https://${testnet ? "testnet." : ""}bscscan.com/tx/${parsed_msg.transactionHash}"
									target="_blank" rel="noopener" class="text-indigo-200 font-semibold">here</a>.
							`,
						})

						this.transaction.open = false
					})
			}
		},
		async getBnbPrice() {
			let {0: price, 1: timestamp} = await this.private_sale.methods.getLatestPrice().call()
			this.bnb_price = this.renderNumber(price, 8)
			this.bnb_price_last_update = DateTime.fromSeconds(+timestamp).toFormat("dd-LL-yyyy HH:mm:ss")

			setInterval(async () => {
				let {0: price, 1: timestamp} = await this.private_sale.methods.getLatestPrice().call()
				this.bnb_price = this.renderNumber(price, 8)
				this.bnb_price_last_update = DateTime.fromSeconds(+timestamp).toFormat("dd-LL-yyyy HH:mm:ss")
			}, 10000)
		}
	},
	watch: {
		theme: function () {
			this.theme === "light"
				? document.querySelector("html").classList.remove("dark")
				: document.querySelector("html").classList.add("dark")
		},
		amount_to_buy() {
			this.meld = +this.amount_to_buy.replace(",", "") *
				+(this.bnb_price.replace(",", "")) / 0.025

			if (isNaN(this.meld)) {
				this.meld = "0.00"
			}
		}
	},
	computed: {
		isTestnet() {
			return testnet
		},
	},
	created() {
		this.provider_options = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					rpc: {
						56: 'https://bsc-dataseed1.binance.org',
						97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
					},
					network: 'binance',
					chainId: testnet ? 97 : 56,
				}
			},
			"custom-binancechainwallet": {
				display: {
					logo: require("@/assets/images/binance.png"),
					name: "Binance Chain Wallet",
					description: "Connect to your Binance Chain Wallet"
				},
				package: true,
				connector: async () => {
					let provider = null;
					if (typeof window.BinanceChain !== 'undefined') {
						provider = window.BinanceChain;
						try {
							await provider.request({method: 'eth_requestAccounts'})
						} catch (error) {
							throw new Error("User Rejected");
						}
					} else {
						throw new Error("No Binance Chain Wallet found");
					}
					1
					return provider;
				}
			},
		}

		this.web3_modal = new Web3Modal({
			network: "binance",
			cacheProvider: !testnet, // optional
			providerOptions: this.provider_options // required
		})

		this.web3 = new web3(testnet ? "https://data-seed-prebsc-1-s1.binance.org:8545/" : "https://bsc-dataseed1.binance.org:443")
		this.private_sale = new this.web3.eth.Contract(private_sale_abi, private_sale_address)

		this.getBnbPrice()
	},
	mounted() {
		//init theme
		document.querySelector("html").classList.contains('dark') ? this.theme = "dark" : this.theme = "light"
	},
});
</script>

<style lang="scss">
#app {
	@apply font-poppins font-bold;
}
</style>
